import { useEffect, useState } from "react";

import getMarqueeCreatives from "api/requests/consumerApi/getMarqueeCreatives";
import { Creative } from "custom-types/AdMarquee";
import { useSelector } from "redux/hooks";
import { getCoordinates } from "redux/selectors/location";

const useFetchMarqueeCreatives = (
  pageType: string,
  initialCreatives?: Creative[],
) => {
  const [creatives, setCreatives] = useState<Creative[]>(
    initialCreatives || [],
  );
  const [loading, setLoading] = useState(!initialCreatives?.length);

  const { lat, lon } = useSelector(getCoordinates);

  useEffect(() => {
    if (!initialCreatives) {
      getMarqueeCreatives(pageType, { lat, lon }).then((data) => {
        if (data) {
          setCreatives(data);
        }

        setLoading(false);
      });
    } else {
      setLoading(false);
    }
  }, [JSON.stringify(initialCreatives), lat, lon, pageType]);

  return {
    creatives,
    loading,
  };
};

export default useFetchMarqueeCreatives;

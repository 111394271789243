import { createClient } from "@leafly-com/consumer-api-fetch";
import { stringify } from "qs";

import publicConfig from "config/public";

const app = publicConfig.serviceRequest.headers.app;
const environment = publicConfig.serviceRequest.headers.environment;

/**
 * This is an experimental client that is generated from the OpenAPI
 * specification for `consumer-api`. This specification is not currently
 * guaranteed to be accurate, and the interface provided by the generated
 * `@leafly-com/consumer-api-fetch` package should be manually tested
 * before it is assumed to be correct.
 *
 * This client exposes methods for each HTTP verb (GET, PUT, etc.), and
 * uses the `fetch` interface under the hood. This allows us to using the
 * native browser functionality instead of a large 3rd party dependency,
 * as well as take advantage of the extentions to `fetch` that Next.js
 * provides for caching responses.
 *
 * See: https://github.com/Leafly-com/api-api/blob/main/consumer-api/openapi/openapi.yml
 * See: https://openapi-ts.dev/openapi-fetch/
 * See: https://developer.mozilla.org/en-US/docs/Web/API/Fetch_API
 * See: https://nextjs.org/docs/app/api-reference/functions/fetch
 */
export default createClient({
  baseUrl: publicConfig.services.consumerApi.url,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
    ...(typeof window === "undefined" && {
      "User-Agent": `Leafly:${app} (${environment})`,
    }),
    "X-App": app,
    "X-Environment": environment,
  },
  querySerializer: (queryParams) =>
    stringify(queryParams, { arrayFormat: "brackets", indices: false }),
});
